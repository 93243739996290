.App {
  text-align: center;
}

.App-header {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: #85bb65;
  padding: 12px;
}
